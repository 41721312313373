import React, {useContext, useState} from "react";
import {Box, Grid, ResponsiveContext, Notification as GrommetNotification} from "grommet";
import {useSession} from "next-auth/react";
import Head from "../Head";
import AppMenu from "../AppMenu";
import ThemeProvider from "../ThemeProvider";
import Dialog from "../Dialog";
import Notification from "../Notification";
import PageHeader from "../PageHeader";
import {DEFAULT_SIDE_PANEL_WIDTH} from "../../constants/Dimensions";
import {getSidebarSize} from "../../utils/sizes";
import { getAdminUser, signOutAndClearAdminUser } from "../../utils/router";
import { EnhancedBox, PositionAbsoluteBox, PositionFixedBox, PositionStickyBox } from "../../utils/styled";
import ConditionalWrapper from "../ConditionalWrapper";
import dynamic from 'next/dynamic';
const SearchComponent = dynamic(() => import('../search/SearchComponent'), {
    ssr: false, // Make sure the component is not server-side rendered
});

const AppLayout = (props) => {
    const {data: session} = useSession();
    const {company, user} = session || {};
    const size = useContext(ResponsiveContext);
    const {
        children,
        title,
        contentTitle, // for when app has secondary menu
        description,
        sidePanelContent,
        sidePanelProps,
        sidePanelWidth = DEFAULT_SIDE_PANEL_WIDTH,
        sidePanelWrapperProps,
        hideHeading,
        showBack,
        backUrl,
        pageControls,
        badge,
        fullWidthHeader,
        largeSpacing,
        subheading,
        background,
        onBackButtonClick,
        pageHeaderProps={},
        pageContainerProps={},
        hasStickyDesktopPageHeader=false
    } = props || {};

    // get initial size of the sidebars to prevent the grids from flickering
    const [sideBarWidth, setSidebarWidth] = useState(getSidebarSize(size));
    const isBeingImpersonated = !!getAdminUser();

    // TODO: the side bar has variable width 
    const twoColumnAreaDesktopConfig = [
        { name: 'sidebar', start: [0, 0], end: [0, 1] },
        { name: 'main', start: [1, 0], end: [1, 1] },
    ];

    const threeColumnAreaDesktopConfig = [
        { name: 'sidebar', start: [0, 0], end: [0, 1] },
        { name: 'secondarySidebar', start: [1, 0], end: [1, 1] },
        { name: 'main', start: [2, 0], end: [2, 1] },
    ];

    const gridSettings = {
        mobile: {
            rows: ['4em', 'auto'],
            columns: ['auto'],
            areas: [
                { name: 'sidebar', start: [0, 0], end: [0, 0] },
                { name: 'main', start: [0, 1], end: [0, 1] },
            ],
            margin: {
                primary: { horizontal: 'none' },
                secondary: { horizontal: 'none' }
            }
        },
        desktop: {
            rows: ['auto', 'auto'],
            columns: [
                sideBarWidth || '0',
                ...(sidePanelContent ? [sidePanelWidth] : []),
                'auto'
            ],
            areas: sidePanelContent ? threeColumnAreaDesktopConfig : twoColumnAreaDesktopConfig,
            margin: {
                primary: { horizontal: 'large' },
                secondary: { horizontal: 'medium', top: 'medium' },
            },
        }
    }

    // Desktop view: secondary menu
    const renderSidebar = (screenSize) => {
        return (screenSize === "mobile") ?
            (<></>) : (
                <Box margin={gridSettings[screenSize].margin.secondary} {...sidePanelProps}>
                    {sidePanelContent}
                </Box>
            );
    }

    return (
        <ThemeProvider>
            <Head title={title} description={description}/>
            {session && (
                <ResponsiveContext.Consumer>
                    {(size) => {
                        const screenSize = size === "small" ? "mobile" : "desktop";
                        return (
                            <Box>
                                <SearchComponent />
                                {isBeingImpersonated &&
                                    <ConditionalWrapper
                                        wrapperIfFalse={children => (<PositionAbsoluteBox width="100%" additionalStyle="z-index: 100;" background="neutral-9">{children}</PositionAbsoluteBox>)}
                                        wrapperIfTrue={children => (<Box>{children}</Box>)}
                                        condition={size === "small"}>
                                        <GrommetNotification
                                            title={`You are currently acting on behalf of ${user.first_name} ${user.last_name} from ${company.business_name}.`}
                                            message="Please remember to"
                                            status="warning"
                                            actions={[
                                                {
                                                    label: 'sign out.',
                                                    onClick: () => signOutAndClearAdminUser()
                                                }
                                            ]}
                                            global
                                            background="status-warning"
                                        />
                                    </ConditionalWrapper>
                                }
                                <Grid
                                    rows={gridSettings[screenSize].rows}
                                    columns={gridSettings[screenSize].columns}
                                    areas={gridSettings[screenSize].areas}
                                >
                                    <Box gridArea="sidebar" height={{ min: (size === 'small' ? '100%' : '600px')}}>
                                        <ConditionalWrapper
                                            condition={size !== "small"}
                                            wrapperIfTrue={children => (
                                                <PositionFixedBox height="100%">
                                                    {children}
                                                </PositionFixedBox>
                                            )}
                                            wrapperIfFalse={children => children}
                                        >
                                            <AppMenu
                                                size={size}
                                                brandColor={company.brand_color}
                                                preference={company.preference}
                                                title={title}/>
                                        </ConditionalWrapper>
                                    </Box>
                                    { size !== "small" && sidePanelContent &&
                                        (<Box
                                            gridArea="secondarySidebar"
                                            height={{ min: (size === 'small' ? '100%' : '600px') }}
                                            background={{color: 'secondary-side-bar'}}
                                            border={{side: 'right'}}
                                            {...sidePanelWrapperProps}>
                                            {renderSidebar(screenSize)}
                                        </Box>)
                                    }
                                    <Box gridArea="main" flex={false} background={background || "background"}>
                                        <Box
                                            flex={false}
                                            width={{max: '100%'}}
                                            pad={{horizontal: size === "small" ? 'small' : 'none', top: 'medium'}}
                                            {...pageContainerProps}>
                                            {size === 'small' && sidePanelContent}
                                            {!hideHeading &&
                                            (
                                                <ConditionalWrapper 
                                                    condition={hasStickyDesktopPageHeader && (size != "small")}
                                                    wrapperIfTrue={children => (
                                                        <PositionStickyBox top additionalStyle="z-index: 20; ">
                                                            {children}
                                                        </PositionStickyBox>
                                                    )}
                                                    wrapperIfFalse={children => children}
                                                >
                                                    <PageHeader
                                                        isStickyHeader={hasStickyDesktopPageHeader}
                                                        background="background"
                                                        title={contentTitle || title}
                                                        showBack={showBack}
                                                        badge={badge}
                                                        backUrl={backUrl}
                                                        onBackButtonClick={onBackButtonClick}
                                                        pageControls={pageControls}
                                                        size={size}
                                                        fullWidthHeader={fullWidthHeader}
                                                        {...(largeSpacing ? {margin: {bottom: 'medium'}} : {})}
                                                        subheading={subheading}
                                                        {...pageHeaderProps} />
                                                </ConditionalWrapper>
                                            )}
                                            <EnhancedBox
                                                styles="position: relative; z-index: 19;"
                                                margin={gridSettings[screenSize].margin.primary}>
                                                {children}
                                            </EnhancedBox>
                                        </Box>
                                        <Notification />
                                        <Dialog />
                                    </Box>
                                </Grid>
                            </Box>
                        );
                    }}
                </ResponsiveContext.Consumer>
            )}
        </ThemeProvider>
    );
};

export default AppLayout;
