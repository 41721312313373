import React from "react";
import {Box} from "grommet";
import {DialogContext} from "./DialogProvider";
import Modal from "./Modal";
import { DIALOG_MODAL_WIDTH } from "../constants/Dimensions";

const Dialog = () => (
    <DialogContext.Consumer>
        { ({
            dialogProps, dialogVisible, showDialog, hideDialog,
        }) => {
            const {
                title,
                message,
                callback,
                ctaColor,
                ctaText,
                ctaTextColor,
                ctaBorderColor,
                ctaHoverColor,
                ctaType,
                customContent,
                showSpinnerWhenProcessing,
                closeDialogOnSubmit,
                hideCancelBtn=false
            } = dialogProps || {};

            if (dialogVisible) {
                return (
                    <Modal 
                        title={title}
                        onClose={hideDialog}
                        onSubmit={closeDialogOnSubmit ? hideDialog : callback}
                        ctaText={ctaText}
                        ctaColor={ctaColor}
                        ctaTextColor={ctaTextColor}
                        ctaBorderColor={ctaBorderColor}
                        ctaHoverColor={ctaHoverColor}
                        hideDeepLink={true}
                        showSpinnerWhenProcessing={showSpinnerWhenProcessing}
                        contentProps={{width: {max: DIALOG_MODAL_WIDTH}}}
                        hideCancelBtn={hideCancelBtn}
                        {...(ctaType === "error" ? {
                            ctaColor: "background",
                            ctaBorderColor: "status-critical",
                            ctaTextColor: "status-critical",
                        } : {})}>
                        {message ? (
                            <Box pad={{vertical: 'small'}} >
                                {message}
                            </Box>
                        ): customContent}
                    </Modal>
                );
            }
            return <></>;
        }}
    </DialogContext.Consumer>
);

export default Dialog;
