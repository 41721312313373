import React, {useState} from "react";
import {Grid} from "grommet";
import {useRouter, withRouter} from "next/router";
import {useSession} from "next-auth/react";
import {isActive} from "../../utils/router";
import {filterLinksFromPermissions, isCompanyShopifyDemo} from "../../utils/permissions";
import {COLLAPSED_DESKTOP_SUBMENU_WIDTH, FULL_DESKTOP_MENU_WIDTH} from "../../constants/Dimensions";
import dynamic from "next/dynamic";

const MobileMenu = dynamic(() => import("./MobileMenu"));
const FullWidthDesktopMenu = dynamic(() => import("./FullWidthDesktopMenu"));

const getMenuComponent = (size, collapsed) => {
    if (size === "small") return MobileMenu;
    return FullWidthDesktopMenu;
};

const Menu = ({ size, title, ...rest }) => {
    const router = useRouter();
    const {data: session} = useSession();
    const permissions = session && session?.user?.permissions;
    const isDemo = isCompanyShopifyDemo(session);
    const links = filterLinksFromPermissions(permissions, isDemo);
    const activeTabData = links.find(link => isActive(link.href, router.pathname, link.regExp, link.exclude));
    const [collapsed, setCollapsed] = useState(false);
    const MenuComponent = getMenuComponent(size, collapsed);
    const hasSubMenu = activeTabData?.subMenu && !(activeTabData?.whitelistPaths || []).includes(router.pathname);

    // check if we need to render subMenu
    return (size === "small" || !hasSubMenu) ?
        (
            <MenuComponent
                links={links}
                activeTabData={activeTabData}
                session={session}
                {...(hasSubMenu ? { collapsed, onCollapsed: setCollapsed } : {})}
                {...rest} />
        ) : (
            <Grid
                fill="vertical"
                columns={[
                    collapsed ? `${COLLAPSED_DESKTOP_SUBMENU_WIDTH}em` : `${FULL_DESKTOP_MENU_WIDTH}em`
                ]}
                rows={['auto']}
                areas={[
                    { name: 'primaryMenu', start: [0, 0], end: [0, 0] },
                ]}>
                <MenuComponent
                    gridArea="primaryMenu"
                    links={links}
                    activeTabData={activeTabData}
                    session={session}
                    collapsed={collapsed}
                    onCollapsed={setCollapsed}
                    {...rest} />
            </Grid>
        );
};

export default withRouter(Menu);
