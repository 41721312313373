import { 
    CONDENSED_DESKTOP_MENU_WIDTH, 
    FULL_DESKTOP_MENU_WIDTH, 
    MOBILE_MENU_HEIGHT
} from "../constants/Dimensions";

// return the sidebar size (in em)
export const getSidebarSize = (size, collapsed=false) => {
    if (size === "small") {
        return `${MOBILE_MENU_HEIGHT}em`;
    }
    if (collapsed) {
        return `${CONDENSED_DESKTOP_MENU_WIDTH}em`;
    }
    return `${FULL_DESKTOP_MENU_WIDTH}em`;
};