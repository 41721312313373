import {Box, Heading, ResponsiveContext, Text} from "grommet";
import React, { useContext, useEffect, useState } from "react";
import Back from "./Back";

const PageHeader = ({
    title = '', 
    showBack = false,
    badge = null, 
    backUrl = null, 
    onBackButtonClick, 
    pageControls=null,
    fullWidthHeader=false, 
    isStickyHeader=false,
    subheading, 
    maxWidth,
    ...rest
}) => {
    const [scrolledPastPageHeader, setScrolledPastPageHeader] = useState(false);
    const size = useContext(ResponsiveContext);
    const isMobile = size === "small";
    let headerWidth = {};

    if (isStickyHeader) {
        headerWidth = "100%";
    } else if (size !== "small" && !fullWidthHeader) {
        headerWidth = {max: maxWidth || 'large'}
    }

    const handleScroll = () => {
        if (isMobile) return; // if it's mobile, ignore scrolling
        const scrollY = window.scrollY;
        const elY = document.getElementById('page-header')?.getBoundingClientRect()?.top;
        
        // This check is in place to prevent flickering.
        // When the browser height is at a certain cut off (i.e. when top bar just start to become sticky), 
        // scrollY ocillates between 0 and 30 because of the style change.
        // This check is to see if the change in scroll is caused by style change or actual scrolling.
        // If it's former, ignore it.
        if (elY > 0 && scrollY === 0) {
            setScrolledPastPageHeader(false);
        } else if (scrollY > 30){
            setScrolledPastPageHeader(true);
        }
    }
    
    useEffect(() => {
        if (typeof window !== "undefined" && isStickyHeader) {
            window.addEventListener("scroll", handleScroll, true);
        }

        // Remove the event listener
        return () => {
            isStickyHeader && window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    const getPageHeaderPadding = () => {
        if (isMobile) {
            return {horizontal: 'xsmall'};
        }
        if (isStickyHeader) {
            return scrolledPastPageHeader ? {horizontal: 'small'} : {horizontal: 'large', bottom: 'small'};
        }
        return {horizontal: 'large', bottom: 'small'};
    }

    return (
        <Box 
            id="page-header"
            direction="row"
            wrap={true}
            flex="grow" 
            align="center"
            justify="between" 
            gap="small"
            width={headerWidth} 
            pad={getPageHeaderPadding()}
            border={isStickyHeader && scrolledPastPageHeader ? {side: 'bottom'} : false}
            {...rest}>
            <Box direction="row" flex="grow" align="center" responsive={true}>   
                {showBack && 
                    (<Box justify="center" align="start" margin={{right: 'xsmall'}}>
                        <Back
                            iconProps={scrolledPastPageHeader ? {size: 'xsmall'} : {}}
                            url={backUrl} 
                            onClick={onBackButtonClick} />
                    </Box>)}
                <Box direction="column" margin={!showBack && scrolledPastPageHeader ? {left: 'small'} : undefined}>
                    <Box 
                        direction={isMobile ? "column" : "row"}
                        margin={(isMobile && badge) ? {bottom: 'medium'} : {}}
                        align={isMobile ? "start" : "center"}
                    >
                        <Heading 
                            level={1} 
                            responsive={false} 
                            size={scrolledPastPageHeader ? "medium" : "large"}
                            margin={{ 
                                top: (size === "small" && badge) ? 'xsmall' : (scrolledPastPageHeader ? 'small' : 'medium'), 
                                bottom: subheading || (isMobile && badge) ? 'xsmall' : (scrolledPastPageHeader ? 'small' : 'medium')
                            }}>
                            {title}
                        </Heading>
                        {badge && <Box justify="around" margin={{ horizontal: 'xsmall' }}>{badge}</Box>}
                    </Box>
                    {subheading && !scrolledPastPageHeader && (<Text>{subheading}</Text>)}
                </Box>
            </Box>
            {pageControls && (
                <Box margin={{vertical: 'small'}} fill={size === "small" ? "horizontal" : undefined}>
                    {pageControls}
                </Box>
            )}
        </Box>
    );
}

export default PageHeader;