import React from "react";
import {useRouter} from "next/router";
import {
    faChevronLeft as IconChevronLeft
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "./FontAwesomeIcon";
import Button from "./Button";
import { goToPrevUrlButSkipSignIn } from "../utils/url";

const Back = ({ url = null, onClick, iconProps={} }) => {
    const router = useRouter();
    return (
        <Button
            event={{element: 'page_back_button'}}
            alignSelf="start"
            margin={{ left: 'xxsmall' }}
            hoverIndicator
            label={<FontAwesomeIcon SelectedIcon={IconChevronLeft} size="small" {...iconProps} />}
            onClick={onClick ?
                onClick :
                goToPrevUrlButSkipSignIn({fallbackUrl: url, router})
            }
        />
    );
};

export default Back;
